import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import showWalletBalanceIcon from "../../../../assets/show-transaction-eye.svg";
import hideWalletBalanceIcon from "../../../../assets/hide-transaction-eye.svg";
import {
  formatCurrency,
  formatMoney,
} from "../../../../helpers/formatCurrency";
import { AiOutlineLock } from "react-icons/ai";
import { RiFileCopyFill } from "react-icons/ri";
import topup from "../../../../assets/dashboard/coin-fill.svg";
import withdraw from "../../../../assets/dashboard/send-plane-fill.svg";
import loans from "../../../../assets/dashboard/loans.svg";
import profile from "../../../../assets/dashboard/user.svg";
import repay from "../../../../assets/web/dashboard/receipt-2.svg";
import contact from "../../../../assets/dashboard/headphone.png";
import Loader from "../../../Loader/Loader";
import { ROUTES } from "../../../../helpers/ROUTES";
import { getDataWithKey } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import copy from "copy-text-to-clipboard";
import AppCard from "../components/AppCard";
import { appInsights } from "../../../AppInsight/AppInsight";
import moment from "moment";
import { Alert, Button } from "antd";
import CompleteVirtualAccountModal from "./components/CompleteVirtualAccountModal";
import useFetchWalletDetails from "../../../../custom-hooks/useFetchWallet";
import useWidgetAggregatorSettings from "../../../../custom-hooks/useWidgetAggregatorSettings";
import { SuccessComponent } from "../SuccessComponent";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import mixpanel from "mixpanel-browser";
import NewOfferPopUp from "../../../../web-widget-reuseable-components/newOfferPopUp/NewOfferPopUp";
import { useFetchPendingWidgetLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";
import redDot from "../../../../assets/red-dot.svg";
import { updateWidgetSettingsAction } from "../../../../redux/actions";

export type UserProp = {
  deviceType: "mobile" | "browser" | string;
  handleWithdrawal?: () => void;
  handlePin?: () => void;
};
const DashboardComponent = ({
  deviceType,
  handleWithdrawal,
  handlePin,
}: UserProp) => {
  let location = useLocation();
  const history = useHistory();
  const widgetSettings = useSelector(
    (state: ReduxStoreModel) => state.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [isLoading, setIsLoading] = useState(false);
  const [lastLoan, setLastLoan] = useState({
    loan_status: "",
    modified_date: "",
    loan_amount: 0,
  });

  const [WalletConsentIsLoading, setWalletConsentIsLoading] = useState(false);
  const [WalletConsentDetails, setWalletConsentDetails] = useState<any>();
  const [loanCurrency, setLoanCurrency] = useState("");
  const [cardAddition, setCardAddition] = useState("");
  const [showWalletBalance, setShowWalletBalance] = useState(false);
  const [error, setError] = useState("");
  const [copiedSuccessMessage, setCopiedSuccessMessage] = useState("");
  const {
    data: fetchWalletDetailsdata,
    isLoading: isLoadingFetchWalletDetails,
    refetch: refetchFetchWalletdetails,
  } = useFetchWalletDetails({
    borrowerId: borrowerDetails?.customer_id,
    aggregatorId: borrowerDetails?.aggregator_id,
  });

  // async function fetchLoanHistory() {
  //   setError("");
  //   setIsLoading(true);
  //   try {
  //     const response = await getDataWithKey(
  //       `${apiEndpoints.getBorrowerLoansInWidget}/${borrowerDetails?.customer_id}?aggregator_id=${borrowerDetails?.aggregator_id}&page=1&pageSize=5`
  //     );
  //     console.log("checking borro  key", response);
  //     if (response.data.length > 0) {
  //       setLoanCurrency(response.data[0]?.currency);
  //       setLastLoan(response.data[0]);
  //     }
  //   } catch (error: any) {
  //     setError(errorHandler(error));
  //     appInsights.trackException({
  //       exception: error,
  //       properties: { fileName: "Dashboard.component.tsx" },
  //     });
  //   }
  //   setIsLoading(false);
  // }

  function newLoanOfferRouteHandler() {
    history.push(ROUTES.widgetNewLoanOffer);
  }

  const {
    data: WidgetAggregatorSettingsData,
    isLoading: isLoadingWidgetSetting,
  } = useWidgetAggregatorSettings({
    countryCode: borrowerDetails?.country_code,
    aggregatorId: borrowerDetails?.aggregator_id,
  });

  const { data: pendingLoanOffer } = useFetchPendingWidgetLoanOffer({
    customer_id: borrowerDetails?.customer_id,
    aggregator_id: borrowerDetails?.aggregator_id,
  });

  // useEffect(() => {
  //   fetchLoanHistory();
  //   //eslint-disable-next-line
  // }, []);
  function handleShowWalletBalance() {
    setShowWalletBalance((prev) => !prev);
  }

  useEffect(() => {
    appInsights.trackPageView({
      name: "Dashboard.component.tsx",
      isLoggedIn: true,
    });

    mixpanel.track_pageview({ page: "Dashboard - Widget" });

    mixpanel.identify(`${borrowerDetails?.email}`);
    mixpanel.people.set({
      $name: `${borrowerDetails?.first_name} ${borrowerDetails?.last_name}`,
      $email: `${borrowerDetails?.email}`,
      phone: `${borrowerDetails?.borrower_phone}`,
      aggregator_id: `${borrowerDetails?.aggregator_id}`,
      product: "embed widget",
      customer_type: `${borrowerDetails?.customer_type}`,
      date_created: new Date().toLocaleString(),
    });
  }, []);

  async function fetchWalletConsent() {
    setError("");
    setWalletConsentIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWalletConsent}?borrower_id=${borrowerDetails?.customer_id}&aggregator_id=${borrowerDetails?.aggregator_id}`,
      );
      if (response.data) {
        setWalletConsentDetails(response.data);
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: DashboardComponent },
      });
    }
    setWalletConsentIsLoading(false);
  }

  useEffect(() => {
    if (
      WidgetAggregatorSettingsData &&
      WidgetAggregatorSettingsData?.length > 0
    ) {
      if (
        widgetSettings?.allow_view_balance &&
        fetchWalletDetailsdata?.isTransactionPinSet === false
      ) {
        history.push(ROUTES.widgetPinOnboarding);
      }
    }
    //eslint-disable-next-line
  }, [isLoadingWidgetSetting, WidgetAggregatorSettingsData]);

  useEffect(() => {
    if (borrowerDetails?.customer_id && borrowerDetails?.aggregator_id) {
      refetchFetchWalletdetails();
      fetchWalletConsent();
    }
    if (localStorage.getItem("card")) {
      setCardAddition(localStorage.getItem("card") || "");
      localStorage.removeItem("card");
    }

    //eslint-disable-next-line
  }, [borrowerDetails?.customer_id, borrowerDetails?.aggregator_id]);

  const handleCopy = () => {
    const content = document?.getElementById("accountNumber")?.textContent;
    if (content) {
      copy(content);
    }
  };

  if (cardAddition === "added") {
    return (
      <SuccessComponent
        btnText="Done"
        body="Your card was added successfully"
        title="Success!"
        handler={() => history.push(ROUTES.widgetCard)}
        type="success"
      />
    );
  }

  if (cardAddition === "failed") {
    return (
      <SuccessComponent
        btnText="Ok"
        body="Something went wrong adding your card"
        title="Failed!"
        handler={() => history.push(ROUTES.widgetCard)}
        type="failure"
      />
    );
  }

  return (
    <div className={styles.dashboard}>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}

      {fetchWalletDetailsdata?.accountStatus === "InActive" && (
        <div className={styles.warningCompleteVirtualAccount}>
          {WalletConsentIsLoading ? (
            <p className="color-mid-blue">Loading...</p>
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <p>
                {" "}
                <i className="fa fa-exclamation-circle"></i>
                <span> Complete your virtual account setup</span>
              </p>
              <div>
                <span
                  className={`${styles.proceedNumber} cursor-pointer`}
                  data-toggle="modal"
                  data-target="#completeVirtualAccountModal"
                >
                  Proceed <i className="fa fa-angle-right"></i>
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <h5 className={styles.title}>
          Hello{" "}
          <small className="font-weight-bold">
            {borrowerDetails?.customer_type === "2"
              ? borrowerDetails?.company_name
              : borrowerDetails?.first_name}
            👋🏼
          </small>
        </h5>
        <div className="d-flex">
          <div>
            {/*<small className="text-muted">NGN</small>*/}
            <small>
              <i className="fa fa-chevron-down text-dark ml-2" />
            </small>
          </div>
          {!borrowerDetails?.isApi && (
            <div className="ml-5">
              <small>
                <i
                  className="fa fa-bell text-muted ml-2"
                  style={{ fontSize: "20px" }}
                />
              </small>
            </div>
          )}
        </div>
      </div>
      {!borrowerDetails?.isApi && (
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{
            marginBottom: `${pendingLoanOffer?.length > 0 ? "8px" : 0}`,
          }}
        >
          <p className="padding-none">Customer category</p>

          <p className="text-dark">{borrowerDetails?.customer_category}</p>
        </div>
      )}
      {copiedSuccessMessage && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={copiedSuccessMessage}
            type="success"
            showIcon
            closable
            onClose={() => setCopiedSuccessMessage("")}
          />
        </div>
      )}
      {pendingLoanOffer?.length > 0 && (
        <NewOfferPopUp newLoanOfferRouteHandler={newLoanOfferRouteHandler} />
      )}
      {borrowerDetails?.currency === "NGN" &&
      widgetSettings?.use_wallet &&
      widgetSettings?.allow_view_balance ? (
        <>
          <br />
          <div className="d-flex justify-content-center">
            <div className={styles.walletCard}>
              {isLoading || isLoadingFetchWalletDetails ? (
                <Loader light />
              ) : (
                <div>
                  <div className="d-flex align-items-center">
                    <p className="text-white mr-2">Wallet Balance</p>
                    {showWalletBalance ? (
                      <img
                        onClick={handleShowWalletBalance}
                        src={hideWalletBalanceIcon}
                        className="img-fluid"
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <img
                        onClick={handleShowWalletBalance}
                        src={showWalletBalanceIcon}
                        className="img-fluid"
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>

                  {showWalletBalance ? (
                    <div>
                      <h3 className="text-white text-center font-weight-bold">
                        <span className="mr-1 font-weight-bold">****</span>
                      </h3>
                    </div>
                  ) : (
                    <div>
                      <h3 className="text-white text-center font-weight-bold">
                        <span className="mr-1 font-weight-bold">&#8358;</span>
                        {formatCurrency(
                          fetchWalletDetailsdata?.availableBalance || 0,
                        )}
                      </h3>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      <br />
      {borrowerDetails?.currency === "NGN" &&
      widgetSettings?.use_wallet &&
      fetchWalletDetailsdata?.accountNumber ? (
        <>
          <p className="text-muted mb-1">Your account number</p>
          <AppCard
            className="d-flex justify-content-between"
            handleCopy={() => {
              handleCopy();
              setCopiedSuccessMessage("copied");
              setTimeout(() => {
                setCopiedSuccessMessage("");
              }, 3000);
            }}
          >
            <div className="d-flex align-items-center">
              <p
                id="accountNumber"
                className="font-weight-bold mr-1 text-primary-dark"
              >
                {fetchWalletDetailsdata?.accountNumber}
              </p>
              <div style={{ marginTop: "-6px" }} className="cursor-pointer">
                <RiFileCopyFill className="text-primary-dark" />
              </div>
            </div>
            <p className="text-primary-dark">
              {fetchWalletDetailsdata?.accountName}
            </p>
            <p className="text-primary-dark" style={{ color: "#200865" }}>
              {fetchWalletDetailsdata?.bankName}
            </p>
          </AppCard>
        </>
      ) : null}
      {borrowerDetails?.currency === "KSH" ? (
        <AppCard
          className="d-flex justify-content-between"
          handleCopy={() => null}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
              <div style={{ maxWidth: "100%" }}>
                <p className="text-break">Last Requested Loan</p>
                <p
                  id="accountNumber"
                  className="font-weight-bold mr-1 text-primary-dark"
                >
                  {formatMoney(lastLoan.loan_amount, loanCurrency)}
                </p>
                {lastLoan.modified_date && (
                  <p>{moment(lastLoan.modified_date).format("LLL")}</p>
                )}

                <p id="loan_status" className="mr-1">
                  <i>{lastLoan.loan_status}</i>
                </p>
              </div>

              <div>
                <Button
                  size="middle"
                  type="primary"
                  onClick={() => history.push(ROUTES.widgetLoanHistory)}
                  disabled={
                    !lastLoan.loan_status || lastLoan.loan_status !== "active"
                  }
                >
                  Repay Loan
                </Button>
              </div>
            </div>
          )}
        </AppCard>
      ) : null}
      <br />
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="text-dark" onClick={() => window.alert(deviceType)}>
          Quick Actions
        </p>
        {borrowerDetails?.currency === "NGN" &&
          widgetSettings?.use_wallet &&
          !borrowerDetails?.isApi && (
            <small
              className="text-primary-dark padding-none"
              style={{ cursor: "pointer" }}
              onClick={() => history.push(ROUTES.widgetTransactions)}
            >
              View Transactions
            </small>
          )}
      </div>
      <div className={`${styles.dashboardgrid}`}>
        {borrowerDetails?.isApi && (
          <div
            className={`${
              deviceType === "mobile" ? styles.cardMobile : styles.card
            } card `}
            onClick={handlePin}
          >
            <div className="card-body d-flex align-items-center justify-content-start h-100 w-100">
              <div className="d-flex align-items-center justify-content-start">
                <div
                  className="icon icon__primary"
                  style={{ background: "#200865" }}
                >
                  <AiOutlineLock
                    className="text-white"
                    size="20px"
                    style={{ marginTop: "-7px" }}
                  />
                </div>
                <small className="text-dark ml-2">Transaction Pin</small>
              </div>
            </div>
          </div>
        )}
        {borrowerDetails?.currency === "NGN" &&
          widgetSettings?.use_wallet &&
          !borrowerDetails?.isApi && (
            <div
              className={`${
                deviceType === "mobile" ? styles.cardMobile : styles.card
              } card `}
              onClick={() => history.push(ROUTES.widgetTopUp)}
            >
              <div className="card-body d-flex align-items-center justify-content-start h-100 w-100 ">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="icon icon__green">
                    <img src={topup} alt="" className="img-fluid" />
                  </div>
                  <small className="text-dark ml-2">Top-up</small>
                </div>
              </div>
            </div>
          )}

        {borrowerDetails?.currency === "NGN" &&
        widgetSettings?.use_wallet &&
        widgetSettings?.allow_withdrawal ? (
          <div
            className={`${
              deviceType === "mobile" ? styles.cardMobile : styles.card
            } card `}
            onClick={() =>
              borrowerDetails?.isApi && handleWithdrawal
                ? handleWithdrawal()
                : history.push(ROUTES.widgetWithdrawal)
            }
          >
            <div className="card-body d-flex align-items-center justify-content-start h-100 w-100 ">
              <div className="d-flex align-items-center justify-content-start">
                <div className="icon icon__lightOrange">
                  <img src={withdraw} alt="" className="img-fluid" />
                </div>
                <small className="text-dark ml-2">Withdraw</small>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {!borrowerDetails?.isApi && (
          <>
            <div
              className={`${
                deviceType === "mobile" ? styles.cardMobile : styles.card
              } card`}
              onClick={() => history.push(ROUTES.widgetLoan)}
              // onClick={() => {
              //   if (!bank_statement_connected) {
              //     if (bank_statement_provider?.toLowerCase() === "okra") {
              //       handleLaunchOkraWidget();
              //     } else {
              //       monoConnect.open();
              //     }
              //   } else {
              //     history.push(ROUTES.widgetLoan);
              //   }
              // }}
            >
              <div
                className="card-body d-flex align-items-center justify-content-start h-100 w-100"
                style={{ position: "relative" }}
              >
                <div className="d-flex align-items-center justify-content-start">
                  <div className="icon icon__primary">
                    <img src={loans} alt="" className="img-fluid" />
                  </div>
                  <small className="text-dark ml-2">Loans</small>
                </div>
                {pendingLoanOffer?.length > 0 && (
                  <img src={redDot} alt="" className={styles.red_dot} />
                )}
              </div>
            </div>

            <div
              className={`${
                deviceType === "mobile" ? styles.cardMobile : styles.card
              } card`}
              onClick={() => history.push(ROUTES.widgetQuickLoanRepayment)}
            >
              <div className="card-body d-flex align-items-center justify-content-start h-100 w-100 ">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="icon icon__secondary">
                    <img src={repay} alt="" className="img-fluid" />
                  </div>
                  <small className="text-dark ml-2">Repay Loan</small>
                </div>
              </div>
            </div>

            <div
              className={`${
                deviceType === "mobile" ? styles.cardMobile : styles.card
              } card`}
              onClick={() => history.push(ROUTES.widgetProfile)}
            >
              <div className="card-body d-flex align-items-center justify-content-start h-100 w-100 ">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="icon icon__secondary">
                    <img src={profile} alt="" className="img-fluid" />
                  </div>
                  <small className="text-dark ml-2">Profile</small>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className={`${
            deviceType === "mobile" ? styles.cardMobile : styles.card
          } card `}
          onClick={() => history.push(ROUTES.widgetContactUs)}
        >
          <div className="card-body d-flex align-items-center justify-content-start h-100 w-100 ">
            <div className="d-flex align-items-center justify-content-start">
              <div className="icon icon__primary">
                <img src={contact} alt="" className="img-fluid w-50" />
              </div>
              <small className="text-dark ml-2">Contact Us</small>
            </div>
          </div>
        </div>
      </div>
      <CompleteVirtualAccountModal
        WalletConsentDetails={WalletConsentDetails}
      />
    </div>
  );
};

export default DashboardComponent;
